import {useSelector} from "react-redux";

function MenuHeader() {

    const global_reducer = useSelector(state => state.global)

    function clickToMain(e){
        window.open(global_reducer.main_url)
    }

    return(
        <div className="header-container">
            <div className="header">
                <div className="toggle-button" onClick={clickToMain}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                         fill="#000000">
                        <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"/>
                    </svg>
                    <i className="fas fa-arrow-left"></i>
                </div>
                <div className="search-box">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                         fill="#707579">
                    <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/>
                    </svg>
                    <input type="text" placeholder="Search"/>
                </div>
            </div>
        </div>
    )

}

export default MenuHeader