import {useSelector} from "react-redux";

function PostItemSubscribe(props){

    const global_reducer = useSelector(state => state.global)

    function clickToMain(url, e){
        // window.open(url+global_reducer.prosper_tracker_suffix)
        window.open(url)
    }

    return (
        <button style={{cursor:"pointer"}} onClick={(e)=>{clickToMain(props.url.slice(0, props.url.lastIndexOf('/')), e)}} className={"subscribe-button"}>ПОДПИСАТЬСЯ {props.url.slice(0, props.url.lastIndexOf('/')).replace('https://t.me/','').toUpperCase()}</button>
    )
}

export default PostItemSubscribe